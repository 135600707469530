const env = process.env.REACT_APP_ENV || "development";
const configData = require(`../../config/config.${env}.json`);
const serverUrl = configData.serverUrl || process.env.REACT_APP_SERVER_URL

class AppConfig {
    public readonly moviesUrl: string = `${serverUrl}movie/`;
    public readonly authUrl: string = `${serverUrl}auth/`;
    public readonly geminiUrl: string = configData.geminiUrl;
    public readonly geminiKey: string = configData.geminiKey;
}

export const appConfig = new AppConfig();